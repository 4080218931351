import styled from "@emotion/styled";

interface Props extends React.HTMLProps<HTMLDivElement> {
  ad: {
    id: string,
    campaignUrl: string,
  }
}

interface WrapperProps {
  clickable: boolean
}

const Wrapper = styled.div<WrapperProps>`
  ${props => props.clickable && `cursor: pointer`}
`;

export function AdWrapper({children, ad, ...otherProps}: Props) {
  const onAdClick = () => {
    if(ad.campaignUrl == "") {
      return;
    }
    setTimeout(() => window.open(ad.campaignUrl, "_blank", "noreferrer noopener"), 50);
  }
  //@ts-ignore
  return <Wrapper data-banner-id={ad.id} onClick={onAdClick} clickable={ad.campaignUrl != ""} {...otherProps}>{children}</Wrapper>
}